<template>
  <div>
    <full-page :options="options" id="fullpage" ref="fullpage" :skip-init="true">
      <div id="warpper">
        <div class="section section1">
          <div class="bg">
            <img src="@public/pc/images/section1.jpg" alt />
          </div>
          <div class="index-top-head">
            <div class="index-head">
              <div class="headlogin">
                <div class="head-tophd">
                  <div>
                    <div class="jbgs-mess fr">
                      <div style="width:30px;height:30px;padding-left: 20px" class="el-icon-message"
                        @click="pushMessageBox"></div>
                    </div>
                    <div class="jbgs-messselect fr">
                      <el-select v-model="language" placeholder="请选择" @change="changeLanguage">
                        <el-option v-for="item in languageOptions" :key="item.value" :label="item.label"
                          :value="item.value"></el-option>
                      </el-select>
                    </div>

                    <div class="head-login" v-if="username">
                      <a class="log-btn fl" href="javascript:;">{{ username }}</a>

                      <a class="reg-btn fl" href="javascript:;" @click="logout">{{ $t('common.logout') }}</a>
                    </div>
                    <div class="head-login" v-else>
                      <a class="log-btn fl" href="javascript:;" @click="login">{{ $t('common.loginIn') }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="head-all">
                <div class="head-mund">
                  <div class="head-logo fl">
                    <img src="@public/pc/images/jiebanglogo01.png" />
                  </div>
                  <div class="head-nav fl">
                    <ul>
                      <li class="pink">
                        <a href="javascript:;" @click="jump('pc-index')">{{ $t('common.home-big') }}</a>
                      </li>
                      <li class="pink" :content="$t('common.govList')"
                        v-tippy="{ arrow: true, arrowType: 'round', animation: 'scale' }">
                        <a href="javascript:;" @click="jump('pc-govList')">{{ $t('common.govList-big') }}</a>
                      </li>
                      <li class="pink" :content="$t('common.entList')"
                        v-tippy="{ arrow: true, arrowType: 'round', animation: 'scale' }">
                        <a href="javascript:;" @click="jump('pc-technology')">{{ $t('common.entList-big') }}</a>
                      </li>
                      <li class="pink" :content="$t('common.achList')"
                        v-tippy="{ arrow: true, arrowType: 'round', animation: 'scale' }">
                        <a href="javascript:;" @click="jump('pc-talent')">{{ $t('common.achList-big') }}</a>
                      </li>
                      <li class="pink" :content="$t('common.talentExpert')"
                        v-tippy="{ arrow: true, arrowType: 'round', animation: 'scale' }">
                        <a href="javascript:;" @click="jump('pc-expert')">{{ $t('common.talentExpert-big') }}</a>
                      </li>
                      <li class="pink" :content="$t('common.serviceAlliance')"
                        v-tippy="{ arrow: true, arrowType: 'round', animation: 'scale' }">
                        <a href="javascript:;" @click="jump('pc-alliance')">{{ $t('common.serviceAlliance-big') }}</a>
                      </li>
                      <li class="pink">
                        <a href="javascript:;" @click="jump('pc-news')">{{ $t('common.news-big') }}</a>
                      </li>
                      <li class="pink" v-if="username" :content="$t('common.personal')"
                        v-tippy="{ arrow: true, arrowType: 'round', animation: 'scale' }">
                        <a href="javascript:;" @click="jump('pc-center')">{{ $t('common.personal-big') }}</a>
                      </li>
                    </ul>
                  </div>

                  <div class="uk-inview">
                    <span>
                      揭榜挂帅 全球引才
                      <br />用全球大脑 解金华难题
                    </span>
                  </div>
                </div>
              </div>
              <video width="100%" id="video" muted autoplay="autoplay" loop preload airplay="allow"
                x5-video-player-type="h5" x5-video-orientation="portraint" x5-playsinline playsinline
                webkit-playsinline="playsinline" :src="srcUrl + videoId" class="video1 video" data-keepplaying></video>
            </div>
          </div>
          <div class="service-home">
            <div class="sertext">
              <p>{{ $t('common.new') }}</p>
              <div class="newdata-conn">
<!--                <span>-->
<!--                  {{ $t('common.accumulativeTotal') }}-->
<!--                  <i>-->
<!--                    <animate-number :from="cumulative" :to="cumulativeNew" duration="2000" easing="easeOutQuad"-->
<!--                      :key="cumulativeNew"></animate-number>-->
<!--                  </i>-->
<!--                  {{ $t('common.entry') }}{{ $t('common.list') }}-->
<!--                </span>-->
                <span>
                  {{ $t('common.fulfilled') }}
                  <i>
                    <animate-number :from="conquered" :to="conqueredNew" duration="2000" easing="easeOutQuad"
                      :key="conqueredNew"></animate-number>
                  </i>
                  {{ $t('common.entry') }}{{ $t('common.list') }}
                </span>
              </div>
              <div class="newdata-exchange">
                <transition name="fade" mode="out-in" enter-class="fade-in-enter" enter-active-class="fade-in-active"
                  leave-class="fade-out-enter" leave-active-class="fade-out-active">
                  <transition-group name="list" tag="ul" :key="currentKey">
                    <li class="nechange-list" v-for="item in cashList" v-show="Current(item.num)" :key="item.num">{{
                      item.title }}</li>
                  </transition-group>
                </transition>
              </div>
            </div>
          </div>
          <div class="counterlist-info">
            <div class="counterlist-conn">
              <ul>
                <swiper class="swiper" ref="swiper" :options="swiperOption">
                  <swiper-slide v-for="recommend in recommendList" :key="recommend.id">
                    <li @click="technologyDetail(recommend.id)">
                      <div class="counterlist-items recommend">
                        <div class="counter-pic">
                          <img
                            v-lazy="{ src: srcUrl + recommend.introduction, error: require('@assets/images/pro_00.png') }" />
                        </div>
                        <div class="counter-titl">{{ recommend.requireName }}</div>
                        <div class="counter-dian">{{ $t('common.highland') }}{{ $t('common.classification') }}：{{
                          recommend.highland_customType }}</div>
                        <div class="counter-hez">{{ $t('common.enterpriseName') }}：{{ recommend.companyName }}</div>
                      </div>
                    </li>
                  </swiper-slide>
                </swiper>
                <div class="counterlist-prev" slot="button-prev"></div>
                <div class="counterlist-next" slot="button-next"></div>
              </ul>
            </div>
          </div>
        </div>
        <div class="section section2">
          <div class="bg">
            <img src="images/section2.jpg" alt />
          </div>
          <div class="index-munk">
            <the-header :lang="language" @updateLanguage="updateLanguage"></the-header>
          </div>

          <div class="card-title">
            <span class="zfbd">{{ $t('common.govList') }}</span>
            <span class="jsxq">{{ $t('common.entList') }}</span>
            <span class="kycg">{{ $t('common.achList') }}</span>
          </div>
          <div class="card-wrapper">
            <div class="card-list" @click="jump('pc-govListCollect-release')">
              <img src="@public/pc/images/plan-5.png" />
            </div>
            <div class="card-list" @click="jump('pc-govList')">
              <img src="@public/pc/images/plan-6.png" />
            </div>
            <div class="card-list" @click="jump('pc-technology-release')">
              <img src="@public/pc/images/plan-1.png" />
            </div>
            <div class="card-list" @click="jump('pc-technology')">
              <img src="@public/pc/images/plan-2.png" />
            </div>
            <div class="card-list" @click="jump('pc-talent-release-new')">
              <img src="@public/pc/images/plan-3.png" />
            </div>
            <div class="card-list" @click="jump('pc-talent')">
              <img src="@public/pc/images/plan-4.png" />
            </div>
          </div>
        </div>
        <div class="section section3">
          <div class="bg">
            <img src="images/section3.jpg" alt />
          </div>
          <div class="index-munk">
            <the-header :lang="language" @updateLanguage="updateLanguage"></the-header>
          </div>
          <div class="service-titl">
            <div class="sertext">
              <p>
                <em>TALENT</em> SPECIALISTS
              </p>
              <span>{{ $t('common.talentExpert') }}</span>
            </div>
          </div>
          <div class="productcenter-nav">
            <div class="more-text">
              <a class="more" @click="moreList">{{ $t('common.more') }} >></a>
            </div>
          </div>
          <div class="productcenter-info">
            <div class="productcenter-conn">
              <ul>
                <swiper class="swiper" ref="expertSwiper" :options="swiperOptionOther">
                  <swiper-slide v-for="expert in expertList" :key="expert.id">
                    <li @click="expertListDetail(expert.id)">
                      <div class="prod-item">
                        <div class="prod-img">
                          <img
                            v-lazy="{ src: srcUrl + expert.portraitAttachId, error: require('@public/pc/images/pro/pro_rc_00.png') }" />
                        </div>
                        <div class="prod-titl">{{ expert.name }}</div>
                        <div class="prod-tips">
                          <span class="tips_01">
                            {{
                              expert.education_customType
                            }}
                          </span>
                          <span class="tips_02">
                            {{
                              expert.professionalCategoryNames
                            }}
                          </span>
                          <span class="tips_01">
                            {{
                              expert.unitName
                            }}
                          </span>
                        </div>
                      </div>
                    </li>
                  </swiper-slide>
                </swiper>
              </ul>
              <div class="cnd-prev"></div>
              <div class="cnd-next"></div>
            </div>
          </div>
        </div>
        <div class="section section4">
          <div class="bg">
            <img src="images/section3.jpg" alt />
          </div>
          <div class="index-munk">
            <the-header :lang="language" @updateLanguage="updateLanguage"></the-header>
          </div>
          <div class="service-titl">
            <div class="sertext">

              <p>
                <em>SERVICE</em> ALLIANCE
              </p>
              <span>{{ $t('common.serviceAlliance') }}</span>
            </div>
          </div>
          <div class="productcenter-nav">
            <div class="more-text">
              <a class="more" @click="moreAllianceList">{{ $t('common.more') }} >></a>
            </div>
          </div>
          <div class="productcenter-info">
            <div class="productcenter-conn">
              <ul>
                <swiper class="swiper" ref="technologySwiper" :options="swiperOptionOther">
                  <swiper-slide v-for="alliance in allianceList" :key="alliance.id">
                    <li @click="allianceListDetail(alliance.id)">
                      <div class="prod-item">
                        <div class="prod-img">
                          <img v-lazy="srcUrl + alliance.attId" alt="" />
                        </div>
                        <div class="prod-titl" v-html="alliance.unitName"></div>
                        <div class="prod-tips">
                          <span class="tips_01">
                            {{
                              alliance.contact
                            }}
                          </span>
                          <span class="tips_02">
                            {{
                              alliance.contactNum
                            }}
                          </span>
                        </div>
                      </div>
                    </li>
                  </swiper-slide>
                </swiper>
              </ul>
              <div class="cnd-prev"></div>
              <div class="cnd-next"></div>
            </div>
          </div>
        </div>
        <div class="section section5">
          <div class="bg">
            <img src="images/section4.jpg" alt />
          </div>
          <div id="container">
            <div id="output"></div>
          </div>
          <div class="bg11"></div>
          <div class="index-munk">
            <the-header :lang="language" @updateLanguage="updateLanguage"></the-header>
          </div>
          <!--服务流程-->
          <div class="service-warp-info">
            <div class="service-warp">
              <div class="service-titl">
                <div class="sertext">
                  <p>
                    <em>SERVICE</em> PROCESS
                  </p>
                  <span>{{ $t('common.serviceProcess') }}</span>
                </div>
              </div>
              <div class="service-items">
                <div class="service-conn">
                  <ul>
                    <li>
                      <div class="sve-wp">
                        <div class="sve-titl">
                          <i class="sve-list01"></i>
                          <p>{{ $t('common.perfectInformation') }}</p>
                          <span></span>
                        </div>
                        <div class="sve-text">{{ $t('common.perfectInformationDesc') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="sve-wp">
                        <div class="sve-titl">
                          <i class="sve-list02"></i>
                          <p>{{ $t('common.evaluationList') }}</p>
                          <span></span>
                        </div>
                        <div class="sve-text">{{ $t('common.evaluationListDesc') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="sve-wp">
                        <div class="sve-titl">
                          <i class="sve-list03"></i>
                          <p>{{ $t('common.resourceSearch') }}</p>
                          <span></span>
                        </div>
                        <div class="sve-text">{{ $t('common.resourceSearchDesc') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="sve-wp">
                        <div class="sve-titl">
                          <i class="sve-list04"></i>
                          <p>{{ $t('common.dockingDiscussion') }}</p>
                          <span></span>
                        </div>
                        <div class="sve-text">{{ $t('common.dockingDiscussionDesc') }}</div>
                      </div>
                    </li>
                    <li>
                      <div class="sve-wp">
                        <div class="sve-titl">
                          <i class="sve-list05"></i>
                          <p>{{ $t('common.comprehensiveTrace') }}</p>
                          <span></span>
                        </div>
                        <div class="sve-text">{{ $t('common.comprehensiveTraceDesc') }}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!---底部--->
          <div class="indexfooter-main">
            <the-footer></the-footer>
          </div>
        </div>
      </div>
    </full-page>
    <help-assistant headerTitle routerName="pc-index"></help-assistant>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { login, logout } from "@/api/user";
import { indexStatisticData, indexRecommend, matchList } from "@/api/index";
import { searchList, queryList } from "@/api/expert";
import { queryEsList, queryAllianceList } from "@/api/alliance";

import TheHeader from "@components/pc/the-header";
import TheFooter from "@components/pc/the-footer";
import helpAssistant from "@components/pc/help-assistant";
export default {
  name: "pcIndex",
  components: { TheHeader, helpAssistant, TheFooter },
  data: function () {
    return {
      recommendList: [],
      technologyList: [],
      talentList: [],
      expertList: [],
      allianceList: [],
      cumulative: 0,
      conquered: 0,
      cumulativeNew: 0,
      conqueredNew: 0,
      srcUrl: process.env.VUE_APP_IMAGE_URL + "/attachment/downAtt?attId=",
      videoId: process.env.VUE_APP_VIDEO_ID,
      //页面滚动 fullpage
      options: {
        licenseKey: "MIT",
        navigation: true,
        navigationPosition: "right",
        showActiveTooltip: true,
        anchors: ["page1", "page2", "page3", "page4", "page5"],
        navigationTooltips: [
          this.$t("common.home"),
          this.$t("common.convenientEntrance"),
          this.$t("common.talentExpert"),
          this.$t("common.serviceAlliance"),
          this.$t("common.serviceProcess")
        ],
        sectionsColor: [
          "#41b883",
          "#ff5f45",
          "#0798ec",
          "#fec401",
          "#1bcee6",
          "#ee1a59",
          "#2c3e4f",
          "#ba5be9",
          "#b4b8ab"
        ]
      },
      //数据左右滑动效果  swiper
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 30,
        direction: "horizontal",
        navigation: {
          nextEl: ".counterlist-next",
          prevEl: ".counterlist-prev"
        }
      },
      swiperOptionOther: {
        slidesPerView: 4,
        spaceBetween: 30,
        direction: "horizontal",
        navigation: {
          nextEl: ".cnd-next",
          prevEl: ".cnd-prev"
        }
      },
      cashList: [],
      currentKey: 1,
      timer: {},
      languageOptions: [
        {
          value: "zh",
          label: "中文"
        },
        {
          value: "en",
          label: "英文"
        }
      ],
      language: this.$store.state.language
    };
  },
  metaInfo: {
    meta: [
      { name: "SiteName", content: "揭榜挂帅-金华揭榜挂帅" },
      { name: "SiteDomain", content: "jbgs.kjj.jinhua.gov.cn" },
      { name: "SiteIDCode", content: "3307000044" }
    ]
  },
  mounted: function () {
    let _this = this;
    let code = Tool.getQueryVariable("code");
    console.log("code", code);
    if (code) {
      //自动登录
      _this.autoLogin(code);
    }
    _this.componentsReady();
    _this.queryStatistic();
    _this.queryRecommend();
    _this.queryIndexExpertList();
    _this.queryIndexAllianceList();
    _this.queryMatchList();
    //js动画
    Victor("container", "output");
  },
  computed: {
    /*username(){
        return this.$store.state.username;
      }*/
    ...mapState(["username"]),
    unreadCount() {
      return this.$store.state.unreadCount;
    }
  },
  methods: {
    ...mapActions(["saveUserName", "saveLanguage"]),
    pushMessageBox() {
      this.$store.state.showMessagePush = !this.$store.state.showMessagePush;
    },
    autoLogin(code) {
      let _this = this;
      //获取token以及用户信息
      queryLoginUserVo({
        code: code
      }).then(res => {
        if (res) {
          //存储token
          localStorage.setItem("userToken", res.token);
          _this.$store.dispatch("saveToken", res.token);
          _this.$store.dispatch("saveUserName", res.loginUser.username);
        }
      });
    },
    afterLoad: function (origin, destination, direction) {
      console.log("After load....");
      console.log(destination);
    },
    componentsReady() {
      this.$refs.fullpage.init();
    },
    //获得首页榜单统计数据
    queryStatistic() {
      let _this = this;
      indexStatisticData({ cityId: "330700" }).then(res => {
        _this.cumulative = _this.cumulativeNew;
        _this.conquered = _this.conqueredNew;
        _this.cumulativeNew = res.cumulative;
        _this.conqueredNew = res.conquered;
      });
    },
    //首页推荐数据
    queryRecommend() {
      let _this = this;
      indexRecommend().then(res => {
        _this.recommendList = res;
      });
    },
    //首页兑榜文案数据
    queryMatchList() {
      let _this = this;
      matchList().then(res => {
        _this.cashList = res;
        _this.timer = setInterval(_this.scrollTimer, 4000);
      });
    },
    //登录
    login() {
      let _this = this;
      //若是开发环境
      if (process.env.NODE_ENV == "development") {
        login({
          userName: "浙江正联网络科技有限公司"
          // userName: "宁波工程学院"
        }).then(res => {
          let redirectUrl = res.redirectUrl;
          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            //存储token
            localStorage.setItem("userToken", res.token);
            _this.$store.dispatch("saveUserName", res.loginUser.username);
            _this.$store.dispatch("saveToken", res.token);
          }
        });
      } else {
        _this.$login.install({ fromPath: _this.$route.fullPath });
      }
    },
    //退出登录
    logout() {
      let _this = this;
      logout().then(res => {
        //退出登录后台返回成功
        if (res) {
          //清除token
          localStorage.removeItem("userToken");
          _this.$store.dispatch("saveUserName", "");
          let fullPath = _this.$route.fullPath;
          if (fullPath.indexOf("code=") != -1) {
            let newPath = fullPath.split("code=")[0];
            _this.$router.replace(newPath);
          }
        }
      });
    },
    //路由切换
    jump(routerName) {
      let _this = this;
      if (routerName === "pc-technology-release") {
        let userToken = localStorage.getItem("userToken");
        if (!userToken) {
          return _this.$login.install({ fromPath: "/pc/technology-release" });
        }
      }
      if (routerName === "pc-talent-release-new") {
        let userToken = localStorage.getItem("userToken");
        if (!userToken) {
          return _this.$login.install({ fromPath: "/pc/talent-release-new" });
        }
      }
      if (routerName === "pc-govListCollect-release") {
        let userToken = localStorage.getItem("userToken");
        if (!userToken) {
          return _this.$login.install({
            fromPath: "/pc/govListCollect-release"
          });
        }
      }
      _this.$router.push({
        name: routerName
      });
    },
    newsDetail(newsId) {
      let _this = this;
      _this.$router.push({
        name: "pc-news-detail",
        query: { id: newsId }
      });
    },
    //人才榜单详情
    expertListDetail(expertListId) {
      let _this = this;
      _this.$router.push({
        name: "expert-detail",
        query: { id: expertListId }
      });
    },
    //服务联盟详情
    allianceListDetail(allianceId) {
      let _this = this;
      _this.$router.push({
        name: "pc-alliance-detail",
        query: { id: allianceId }
      });
    },
    //榜单详情
    technologyDetail(technologyId) {
      let _this = this;
      _this.$router.push({
        name: "pc-technology-detail",
        query: { id: technologyId }
      });
    },
    //成果榜单详情
    talentDetail(talentId) {
      let _this = this;
      _this.$router.push({
        name: "pc-talent-detail",
        query: { id: talentId }
      });
    },
    //查询首页专家榜单
    queryIndexExpertList() {
      let _this = this;
      //expertList
      queryList({
        page: 1,
        pageSize: 15
      }).then(res => {
        _this.expertList = res.records;
      });
    },
    //查询首页专家榜单
    queryIndexAllianceList() {
      let _this = this;
      let queryData = {
        pageIndex: 1,
        pageSize: 15
      };
      queryAllianceList(queryData).then(res => {
        _this.allianceList = res.records;
      });
    },
    //更多
    moreList() {
      let _this = this;
      _this.jump("pc-expert");
    },
    moreAllianceList() {
      let _this = this;
      _this.jump("pc-alliance");
    },
    Current(id) {
      let _this = this;
      if (_this.currentKey === id) {
        return true;
      } else {
        return false;
      }
    },
    scrollTimer() {
      let _this = this;
      let length = _this.cashList.length;
      if (_this.currentKey >= length) {
        _this.currentKey = 1;
      } else {
        _this.currentKey++;
      }
      // if (_this.currentKey % 2 == 0) {
      //   _this.queryStatistic();
      // }
    },
    changeLanguage(value) {
      this.$store.dispatch("saveLanguage", value);
      this.$i18n.locale = this.language;
    },
    updateLanguage(value) {
      this.language = value;
    },
    jumpKjj() {
      window.open("http://kjj.jinhua.gov.cn", "_blank");
    }
  }
};
</script>

<style scoped>
@import "~@public/pc/css/public.css";
@import "~@public/pc/css/index.css";
@import "~@public/pc/css/component.css";

.fade-in-active,
.fade-out-active {
  transition: all 1s ease;
}

.fade-in-enter {
  opacity: 0;
  transform: translateY(30px);
}

.fade-out-active {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
<style lang="less" scoped>
.jbgs-messselect {
  height: auto;
  overflow: hidden;
  display: block;
  width: 100px;
  margin-left: 30px;

  .el-select {
    display: flex;
    align-items: center;
  }

  /deep/.el-input--suffix {
    height: 30px;
    display: flex;
    align-items: center;
  }

  /deep/.el-input__suffix {
    height: 30px;
    display: flex;
    align-items: center;
  }

  /deep/.el-input__inner {
    height: 30px;
    line-height: 30px;
    padding-right: 0;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    border-radius: 2px;
    border: none;
  }

  /deep/.el-select /deep/.el-input /deep/.el-select__caret {
    color: #fff;
  }
}
</style>
