export default {
    common: {
        'phone': 'Mobile',
        'home': 'Home',
        'home-big': 'HOME',
        'chatOnline': 'Chat Online',
        'mychatOnline': 'Chat Online',
        'search': 'Search',
        'logout': 'Logout',
        'city': 'City',
        'area': 'County',
        'personal': 'Personal Center',
        'personal-big': 'P-C',
        'welcome': 'Welcome to the unveiling platform',
        'news': 'News',
        'news-big': 'NEWS',
        'policy-big':'POLICIES',
        'details': 'Details',
        'viewLatest': 'View Latest',
        'defaultDisplay': 'Default Display',
        'keywords': 'Please enter keywords',
        'noData': 'Oh, no data yet!',
        'industryType': 'Industry Type',
        'industryField': 'High-tech field',
        'threeHighLand': '315 System',
        'cooperationType': 'Cooperation Type',
        'projectBudget': 'Project Budget',
        'listStatus': 'List Status',
        'releaseDemand': 'Release List Demand',
        'promptlyUnveil': 'Promptly Unveil',
        'totalFound': 'Total found',
        'qualified': 'Qualified',
        'convenientEntrance': 'Convenient Entrance',
        'govList': 'Government List',
        'govList-big': 'G-L',
        'entList': 'Enterprise List',
        'entList-big': 'E-L',
        'achList': 'Achievement List',
        'achList-big': 'A-L',
        'talentProject': 'Talent Project',
        'enterEnterprise': 'Hundred doctors enter the enterprise',
        'enterEnterprise-big': 'H-D-E-E',
        'talentExpert': 'Talent Expert',
        'talentExpert-big': 'T-E',
        'serviceAlliance': 'Service Alliance',
        'serviceAlliance-big': 'S-A',
        'new': 'New',
        'more': 'More',
        'serviceProcess': 'Service Process',
        'generalList': 'General List',
        'listType': 'List Type',
        'projectType': 'Project Type',
        'jointPublishing': 'Joint Publishing',
        'demandCollection': 'Submit Demand Collection',
        'baseInformation': 'Base Information',
        'userName': 'UserName',
        'researchField': 'Research Field',
        'resourceType': 'Resource Type',
        'resourceName': 'Resource Name',
        'firstField': 'First field',
        'secondField': 'Second field',
        'majorType': 'Major Type',
        'technicalField': 'Technical Field',
        'expertName': 'Expert Name',
        'education': 'Education',
        'unitName': 'Unit Name',
        'wantPublish': 'Publish',
        'expertRegister': 'Expert Register',
        'myList': 'My List',
        'over': "It's over",
        'myUnveiling': 'My Unveiling',
        'myAppointment': 'My Appointment',
        'myCollection': 'My Collection',
        'release': 'Release Demand',
        'outcomeEvaluation': 'Outcome Evaluation',
        'overseaEngineer': 'Oversea Engineer',
        'governmentListCollect': 'Government List Collect',
        'recommendList': 'recommend List',
        'myExposureRisk': 'My Exposure Risk',
        'myTechnologyLoan': 'My Technology Loan',
        'publishedExperts': 'Published Experts',
        'mineExperts': 'myExperts',
        'keyword': 'keywords',
        'pendingReview': 'Pending Review',
        'all': 'All',
        'pass': 'Pass',
        'notPass': 'Not Pass',
        'publishing': 'Publishing',
        'signed': 'Signed',
        'approved': 'Approved',
        'conquered': 'Conquered',
        'cancelled': 'Cancelled',
        'enterpriseName': 'Enterpris Name',
        'companyName': 'Company Name',
        'accumulativeTotal': 'Accumulative Total',
        'entry': 'Entry',
        'list': 'List',
        'fulfilled': 'Fulfilled',
        'loginIn': 'Login In',
        'classification': 'Classification',
        'highland': 'Highland',
        'perfectInformation': 'Perfect information',
        'perfectInformationDesc': 'The government list has the qualification to publish the list after the user binds the unit information, and the scientific research institutes, new R&D institutions, teams, individuals and cooperative institutions need to upload the relevant qualification materials to have the qualification to publish the list.',
        'evaluationList': 'Evaluation List',
        'evaluationListDesc': 'Enterprises fill in the government list, and the platform evaluates the feasibility of the list. After the assessment is passed, the government list will be displayed on the platform and accurately pushed',
        'resourceSearch': 'Resource Search',
        'resourceSearchDesc': 'After the release of the list of enterprises and governments, the platform news and information to learn about the list information and take the initiative to reveal the list, and the service center and technology transfer center will conduct oriented visits to reveal the list team, research institutes and cooperative institutions',
        'dockingDiscussion': 'Docking Discussion',
        'dockingDiscussionDesc': 'Accurate online information push, offline platform active service, the two sides of the list and the release of in-depth negotiations, signed the key agreement, overcome technical problems',
        'comprehensiveTrace': 'Comprehensive Trace',
        'comprehensiveTraceDesc': 'The platform tracks the whole cooperation process between the two parties, and provides supporting policy interpretation and policy declaration',
        'proactivelyDesignProjectLists': 'Proactively design project lists',
        'bangE': 'BangE',
        'staySigned': 'Stay Signed',
        'signing': 'Signing',
        'immediatelyDeclare': 'Immediately Declare',
        'simpleCompany': 'Company',
        'myriad': 'Myriad',
        'industry': 'Industry',
        'region': 'Region',
        'listContent': 'List Content',
        'jieBangSubject': 'JieBang subject',
        'implementationWay': 'Implementation Way',
        'totalBudgetInputForKeyProjects': 'Total budget input for key projects',
        'researchTimeLimit': 'Research Time Limit',
        'declarationStartTime': 'Start time of declaration',
        'declarationEndTime': 'End time of declaration',
        'collection': 'Collection',
        'cancel': 'Cancel',
        'mainDesignContent': 'Main design content',
        'mainTechnicalIndicators': 'Main technical indicators',
        'commenList': 'Commen List',
        'noteInfo': 'Note Info',
        'before': 'Before',
        'reply': 'Reply',
        'comment': 'Comment',
        'wantCollect': 'I want to collect',
        'projectInformation': 'Project information',
        'pleaseFillInTheFollowingCarefully': 'Please fill in the following carefully',
        'requireName': 'RequireName',
        'R&D': 'R&D',
        'experiment': 'Experiment',
        'period': 'period',
        'demand': 'Demand',
        'type': 'Type',
        'strategic': 'Strategic',
        'planning': 'Planning',
        'market': 'Market',
        'recommend': 'Recommend',
        'source': 'Source',
        'municipalGovernmentDepart': 'Municipal Government Department',
        'academics': 'Academics',
        'institution': 'Institution',
        'name': 'Name',
        'researchSignificance': 'Conquer background and significance',
        'word': 'Word',
        'mainResearchDevelopmentContent': 'Main research development content',
        'achievement': 'Achievement',
        'info': 'Info',
        'expectedLandmarkAchievementAndLevel': 'Expected landmark achievement and level',
        'technology': 'Technology',
        'advanced': 'Advanced',
        'level': 'Level',
        'domestic': 'Domestic',
        'international': 'International',
        'referenceUnitAndProductModel': 'Reference unit and product model',
        'benchmarkingProductsTechnology': 'Benchmarking products technology',
        'R&dProductsTechnology': 'R&d products technologies',
        'expectedProgress': 'Expected Progress',
        'canChooseMore': 'Can Choose More',
        'leadDepopulatedZoneTech': 'Lead (depopulated zone) technology',
        'domesticSubstitutionDemonstrationApplication': 'To realize the demonstration application of domestic substitution',
        'domesticInsteadTrial': 'Carry out the trial of domestic substitution',
        'domesticSubstitutionPrototype': 'Develop a prototype of domestic substitution',
        'other': 'Other',
        'unitInfo': 'Unit Info',
        'recommendTotalRDInvest': 'Recommend total R&D investment',
        'recommendAdvantagesUnit': 'Recommend Advantages Unit',
        'fillInUnit': 'Fill in the unit',
        'informant': 'Informant',
        'contactPhoneNumber': 'Contact Phone Number',
        'validityPeriod': 'Validity Period',
        'negotiable': 'Negotiable',
        'applyCheckList': 'Apply Check List',
        'haveDifficulty': 'Have difficulty',
        'findResources': 'find resources',
        'desiredGoals': 'Desired Goals',
        'enterpriseProfile': 'Enterprise Profile',
        'bigMarket': 'BigMarket',
        'contactNumber': 'Contact Number',
        'enterprise': 'Enterprise',
        'platform': 'Platform',
        'operationStaff': 'Operation Staff',
        'wantBillboard': 'I want to billboard',
        'promise': 'Promise',
        'platformPromiseDesc': 'The information you submitted has been encrypted by the system and will not be leaked. Please rest assured to fill in!',
        'cooperationWay': 'Cooperation Way',
        'degreeLevel': 'Degree Level',
        'emergency': 'Emergency',
        'noUrgent': 'Not urgent',
        'municipal': 'Municipal',
        'counties': 'Counties',
        'expectTotalProjectInvest': 'Expect total project investment (ten thousand yuan)',
        'listAmountType': 'List Amount Type',
        'listAmount': 'List Amount',
        'listAmountSimple': 'List Amount',
        'listMinAmount': 'List Min Amount',
        'listMaxAmount': 'List Max Amount',
        'contact': 'Contact',
        'listPublicDegree': 'List Public Degree',
        'partiallyExposedType': 'Partially Exposed Type',
        'listIntroducePictures': 'List Introduce Picture',
        'industrialChainName': 'Top Ten Industrial Chains',
        'listIntroducePicturesDesc': 'Please upload pictures of corporate image or list of problems related pictures',
        'supplementaryInformation': 'Supplementary Information',
        'upDetailPicturesVideos': 'Upload detail pictures/videos',
        "companyDetailPicturesVideosFor": "Introduction of enterprise/technical requirements;",
        'upDetailPicturesVideosDesc': 'The image size must be less than 5 meters. The image format can be JPG, GIF, BMP, or PNG More than 800×800 pictures',
        'clickSelectImgVedio': 'select image/video',
        'confirmSubImmediately': 'Confirm and apply for release immediately',
        'saveDraft': 'SaveDraft',
        'applyTrophy': 'Apply Trophy',
        'awardSubsidyProject': 'Award (subsidy) project',
        'userType': 'User Type',
        'whetherBidding': 'Whether bidding',
        'yes': 'Yes',
        'no': 'No',
        'applyUnit': 'Apply Unit',
        'applyPerson': 'Apply Personal',
        'socialCreditCode': 'Unified social credit code',
        'idCard': 'IdCard',
        'legalRepresentative': 'Legal representative',
        'unitNature': 'Unit Nature',
        'countyCityDistrict': 'County/City/district',
        'townStreetArea': 'Town/Street/Area',
        'depositBank': 'Deposit Bank',
        'bankAccount': 'Bank Account',
        'countySubsidyAmount': 'County Subsidy Amount',
        'citySubsidyAmount': 'City Subsidy Amount',
        'allSubsidyAmount': 'Advice All Subsidy Amount',
        'availableMatchMoney': 'Available Match Money',
        'applyReason': 'Apply Reason',
        'billboardTime': 'Billboard Time',
        'publishListTime': 'Publish List time',
        'publishListPerson': 'Publish List Person',
        'publishListTeam': 'Publish List Team',
        'contractParta': 'Contract Parta',
        'contractPartb': 'Contract Partb',
        'contractPartc': 'Contract Partc',
        'contractType': 'Contract Type',
        'contractStartTime': 'Contract Start Time',
        'contractEndTime': 'Contract End Time',
        'contractAmount': 'Contract Amount',
        'tappingListExpert': 'Tapping List Expert',
        'add': 'Add',
        'serialNumber': 'Serial Number',
        'expertCertificateType': 'Expert Certificate Type',
        'expertCertificateNumber': 'Expert Certificate Number',
        'whetherPutinStorage': 'Whether put in storage',
        'operation': 'Operation',
        'modify': 'Modify',
        'delete': 'Delete',
        'syncStorageAndClose': 'Sync Storage And Close',
        'supportingMaterials': 'Supporting Materials',
        'paymentVoucher': 'Payment Voucher',
        'clickPaymentVoucher': 'Click Upload payment voucher',
        'paymentVoucherTip': 'Please upload the payment voucher, which does not exceed 10M',
        'contractVoucher': 'Contract Voucher',
        'contractEnroll': 'Contract Enroll',
        'clickContractVoucher': 'Click Upload contract voucher',
        'clickContractEnroll': 'Click Upload contract enroll',
        'clickInvoice': 'Click Upload invoice',
        'invoiceTip': 'Please upload the invoice, which does not exceed 10M',
        'uploadContractVoucher': 'Upload Contract Voucher',
        'contractVoucherTip': 'Please upload the contract voucher, which does not exceed 10M',
        'contractEnrollTip': 'Please upload the contract enroll, which does not exceed 10M',
        'commitmentLetter': 'Commitment Letter',
        'templateSignUpload': 'Sign, seal and upload according to the template',
        'commitmentLetterDownload': 'Download the template of the letter of Commitment',
        'clickCommitmentLetter': 'Click Upload Letter of Commitment',
        'commitmentLetterTip': 'Please upload the letter of commitment not exceeding 10M',
        'projectSection': 'Project Section',
        'specialAuditReport': 'Special Audit Report',
        'projectSectionDownload': 'Project section template download',
        'clickProjectSection': 'Click the upload project section',
        'clickSpecialAuditReport': 'Click the Upload special audit report',
        'projectSectionTip': 'Please upload a project section no larger than 10 MB',
        'specialAuditReportTip': 'Please upload a special audit report no larger than 10 MB',
        'listAuctionProofMaterials': 'List auction proof materials',
        'clickListAuctionProofMaterials': 'Click Upload Auction list proof materials',
        'listAuctionProofMaterialsTip': 'Please upload bidding list proof materials, and no more than 10M',
        'whetherPatentTechnology': 'Whether Patent Technology',
        'wantTransfer': 'I want to transfer',
        'myRelease': 'myRelease',
        'applyImmediately': 'Apply Immediately',
        'maturity': 'Maturity',
        'digitalizeMaturity':'digitalize maturity',
        'transferAmount': 'Transfer Amount',
        'achievementSummary': 'Achievement Summary',
        'researchTeamIntroduce': 'Research Team Introduce',
        'resultApplicationIndustry': 'Result Application Industry',
        'coreProductName': 'Core Product Name',
        'mainApplicationAreas': 'Main Application Areas',
        'featuresInIndustry': 'Features in the industry',
        'marketProspectAnalysis': 'Market Prospect Analysis',
        'patentInformation': 'Patent Information',
        'patentName': 'Patent Name',
        'patentdesc': 'Patent description',
        'advancedTechnology': 'Advanced Technology',
        'applicationCase': 'Application Case',
        'targetEnterprisesCustomers': 'Target enterprises or customers after product transformation',
        'businessModel': 'Business Model',
        'developmentPlan': 'Development Plan',
        'revenueForecastThreeYear': 'Revenue forecast for the next three years',
        'financingPlan': 'Financing Plan',
        'listName': 'List Name',
        'ownershipAchievementUnit': 'Ownership Achievement Unit',
        'whetherPropertyDispute': 'Whether property dispute',
        'whetherPropertyRights': 'WhetherPropertyRights',
        'propertyBatchNumber': 'Intellectual property batch number',
        'propertyRightsDesc': 'Property rights Description',
        'achievementConversionAmount': ' achievement conversion Amount(tenThousand yuan)',
        'resultsCoreProductPhotosVideos': ' Results Core product photos or videos',
        'resultsCoreProductDesc': 'Results Core product introduction',
        'applicationCasePhotosVideos': 'Application case photos or videos',
        'applicationCaseDesc': 'Application Case introduction',
        'coverPhoto': 'Cover Photo',
        'clickSelectImg': 'Click Select Image',
        'compellation': 'Compellation',
        'dutuies': 'Dutuies',
        'major': 'Major',
        'workExperience': 'Work Experience',
        'workPerformance': 'Work Performance',
        'reviewedNoModify': 'Reviewed and no modify',
        'submit': 'Submit',
        'resultPhotsAndVideos': 'Results photos and videos',
        'productCompetitivenessAnalysis': 'Results Product competitiveness analysis photos or video',
        'productCompetitivenessAnalysisDesc': 'Analysis and introduction of product competitiveness',
        'talentExpertRegister': 'Registration of talents/experts',
        'wantRegister': 'I want to register',
        'birthday': 'Birthday',
        'gender': 'Gender',
        'certificateType': 'CertificateType',
        'certificateNumber': 'CertificateNumber',
        'mobilePhone': 'MobilePhone',
        'email': 'Email',
        'technicalTitle': 'Technical titles',
        'titleLevel': 'Title level',
        'degree': 'Degree',
        'graduateSchool': 'Graduate School',
        'fullUnitName': 'Full Unit Name',
        'schoolDepartment': 'School or department',
        'inProvince': 'Province',
        'inCity': 'City',
        'inCounty': 'County',
        'researchDirection': 'Research Direction',
        'academicAchievement': 'Academic Achievement',
        'headPortrait': 'Head Portrait',
        'headPortraitDesc': 'Uploading your profile picture makes it more informative (one-inch photo)',
        'clearData': 'ClearData',
        'firstLevelDiscipline': 'First-level discipline',
        'secondLevelDiscipline': 'Second-level discipline',
        'thirdLevelDiscipline': 'Third-level discipline',
        'determine': 'Determine',
        'clickUpload': 'ClickUpload',
        'uplimit50m': 'Upload files no larger than 50M',
        'title': 'Title',
        'state': 'State',
        'watchList': 'Watch List',
        'watchJieBang': 'watch JieBang',
        'reviewingList': 'reviewing List',
        'butAcceptance': 'ButAcceptance',
        'canBeTrophy': 'CanBeTrophy',
        'completed': 'Completed',
        'shelves': 'OffShelves',
        'returned': 'Returned',
        'refused': 'Refused',
        'field': 'Field',
        'draft': 'Draft',
        'auditReturn': 'ReviewReturn',
        'auditRefused': 'AuditRefused',
        'expertReview': 'ExpertReview',
        'waitingPayment': 'waitingPayment',
        'reviewCompleted': 'ReviewCompleted',
        'reviewPass': 'passReview',
        'reviewReturn': 'ReviewReturn',
        'reviewRefused': 'ReviewRefused',
        'reviewExample': 'ReviewExample',
        'resultsName': 'ResultsName',
        'entrustingUnitName': 'EntrustingUnitName',
        'evaluationType': 'EvaluationType',
        'applicationStatus': 'ApplicationStatus',
        'notDocking': 'Not Docking',
        'docking': 'Docking',
        'evaluationOfMe': 'Evaluation Of Me',
        'haveEvaluation': 'Have Evaluation',
        'startEvaluation': 'Start Evaluation',
        'notApply': 'Not Apply',
        'toAudit': 'ToAudit',
        'auditCompleted': 'Audit Completed',
        'comptrollerRefused': 'Comptroller Refused',
        'comptrollerReturn': 'Comptroller Return',
        'confirmToRedeem': 'Confirm To Redeem',
        'detail': 'Detail',
        'returnReason': 'Return Reason',
        'refuseReason': 'Refuse Reason',
        'jiebangDetail': 'JiebangDetail',
        'haveRead': 'HaveRead',
        'newsheadlines': 'News Headlines',
        'compatibility': 'Compatibility',
        'watch': 'watch',
        'toBeConfirm': 'To be confirmed',
        'forDocking': 'for Docking',
        'belongMunicipal': 'belong Municipal',
        'appointmentTitle': 'appointmentTitle',
        'belongList': 'BelongList',
        'bookingStatus': 'BookingStatus',
        'policyBy': 'PolicyBy',
        'unveilingCompanyNum': 'unveilingCompanyNum',
        'cooperationMode': 'cooperationMode',
        'returnPolicy': 'Return policy',
        'beInsuredName': 'BeInsuredName',
        'loanBy': 'Loan By',
        'loanBack': 'Loan Back',
        'loanRefuse': 'Loan Refuse',
        'R&DName': 'R&DName',
        'listLoanOrNot': 'List loan or not',
        'publishGovernmentListCall': 'Publish Collects Of Goverment List',
        'publish': 'Publish',
        'sinceCrack': 'Since Crack',
        'ended': 'Ended',
        'undercarriage': 'Undercarriage',
        'removeList': 'delete',
        'watchCheckList': 'Watch CheckList',
        'switchToGovernmentList': 'Switch To Government List',
        'send': 'Send',
        'whatCanIDoForYou': 'Hello, what can I do for you?',
        'jieBangHeaded': 'JieBang headed',
        'machineService': 'Machine intelligence customer service at your service',
        'inviteYouJieBang': 'Invite You JieBang',
        'latestBillboard': 'latest Billboard',
        'inBatch': 'In a batch',
        'fitWithMe': 'Fit with me',
        'participateInImmediately': 'Immediately to participate in',
        'jieBangUser': 'JieBang User',
        'jieBangType': 'JieBang Type',
        'personage': 'Personal',
        'team': 'Team',
        'appointmentToEnterprise': 'Appointment To Enterprise',
        'begin': 'Begin',
        'end': 'End',
        'signOrNot': 'SignOrNot',
        'noSign': 'Not Sign',
        'signingTime': 'Signing Time',
        'contractInformation': 'Contract Information',
        'supplementary': 'Supplementary',
        'startCheckList': 'Start check list',
        'checkListInfo': 'Check list Information',
        'hardInformation': 'Hard information',
        'titles': 'titles',
        'professionalField': 'professionalField',
        'workUnits': 'WorkUnits',
        'teamNumber': 'TeamNumber',
        'memberName': 'MemberName',
        'planOutlining': 'Plan outlining',
        'planAttach': 'plan files',
        'attachment': 'Attachment',
        'download': 'Download',
        'agreeToSign': 'Agree To Sign',
        'redeemedAmount': 'Redeemed Amount',
        'wanYuan': 'ten thousand',
        'redeemAmount': 'redeem Amount',
        'continueCash': 'Continue Cash',
        'cashTime': 'CashTime',
        'principalPartya': 'Principal (Party A)',
        'localPlace': 'Local Place',
        'projectContactPerson': 'Project Contact Person',
        'correspondenceAddress': 'Correspondence address',
        'offphone': 'Phone',
        'tax': 'Tax',
        'principalPartyb': 'Principal (Party B)',
        'partyCName': 'name of the party c',
        'signingSite': 'Signing Site',
        'contractAmount2': 'Contract Amount(ten thousand)',
        'confirmContractSign': 'Confirmation of contract signing',
        'downloadContractTemplate': 'Download Contract Template',
        'attackListEncounteredProblems': 'Attack the list encountered problems',
        'enterpriseStartTime': 'Enterprise StartTime',
        'enterpriseEndTime': 'Enterprise StartTime',
        'unveilingApplyTime': 'Unveiling ApplyTime',
        'engagedIndustry': 'Engaged Industry',
        'products': 'Products',
        'basicIntroduction': 'Basic Introduction',
        'jieBangState': 'JieBang State',
        'cancelled2': 'Cancelled2',
        'paymentWay': 'Payment Way',
        'disposable': 'Disposable',
        'installment': 'Installment',
        'cashRecord': 'CashRecord',
        'cashTime2': 'CashTime',
        'cashOperator': 'CashOperator',
        'personNumber': 'Person Number',
        'planAttachment': 'Plan Attachment',
        'dockingWay': 'Docking Way',
        'weChat': 'WeChat',
        'mailbox': 'Mailbox',
        'appointmentTime': 'Appointment Time',
        'appointmentEnterpriseTime': 'Appointment Enterprise Time',
        'subImmediately': 'Immediately submit',
        'creditRating': 'CreditRating',
        'importantNote': 'Important note',
        'enterpriseCreditInformation': 'Enterprise Credit Information',
        'temporarilyNo': 'TemporarilyNo',
        'transformationPlan': 'Transformation plan',
        'attachmentUpload': 'Attachment Upload',
        'appointmentDockingTime': 'Appointment Docking Time',
        'dockingTime': 'docking Time',
        'serviceCenter': 'Jiebang service center',
        'contactAddress': 'Contact address',
        'dockingList': 'Docking list',
        'amount': 'Amount',
        'evaluationForMe': 'evaluation for me',
        'evaluationState': 'Evaluation state',
        'watchEvaluation': 'Watch Evaluation',
        'evaluationInfo': 'Evaluation Info',
        'dockingDetail': 'Docking detail',
        'leaveWord': 'Leave Word',
        'contactImmediately': 'Contact Immediately',
        'overallScore': 'Overall Score',
        'technicalAbility': 'Technical ability',
        'workAttitude': 'Work Attitude',
        'serviceAttitude': 'Service attitude',
        'evaluationLabel': 'Evaluation Label',
        'evaluationContent': 'Evaluation Content',
        'suggestionAndComment': 'Suggestion and Comment',
        'submitEvaluation': 'Submit Evaluation',
        'workFitDegree': 'Degree of working fit',
        'paymentTimeliness': 'PaymentTime liness',
        'foundationR&D': 'Foundation of enterprise R&D',
        'enterpriseR&DCapability': 'Enterprise R&D capability',
        'applicant': 'Applicant',
        'cannoContact': 'Can not contact',
        'added': 'Added',
        'listDocking': 'List Docking',
        'listJieBang': 'List JieBang',
        'sign': 'Sign',
        'cash': 'Cash',
        'complete': 'Complete',
        'watchPerformance': 'Watch Performance',
        'unitWanyuan': 'Unit: Ten thousand Yuan',
        'cashTimes': 'Cash Times',
        'canFillInAmount': 'Can FillIn Amount',
        'achievementTransformationPerformance': 'Achievement transformation performance',
        'marketOperatingIncome': 'Market Operating Income',
        'contractMaterials': 'Contract Materials',
        'gongguanType': 'Research type',
        'belongField': 'Control field',
        'researchType': 'Research type',
        'mainField': 'Main type',
        'firstInnovationChain': 'Belong innovation chain',
        'secondInnovationChain': 'Second innovation chain',
        'thirdInnovationChain': 'Third innovation chain',
        'investment': 'Advice total R&D investment(ten thousand)',
        'timeLimit': 'Conquer time limit',
        'conquerGoal': 'Conquer goal',
        'solvedScientific': 'Solved scientific',
        'solvedTechnical': 'Solved technical',
        'achievementNature': 'Achievement nature',
        'unitProduct': 'Benchmarking',
        'relySituation': 'Advantage',
        'demandUnit': 'Demand unit',
        'recommendUnit': 'Recommend unit',
        'achievementLevel': 'Achievement talent & level',
        'adopt': 'Adopted',
        'unAdopt': 'Not adopted',
        'mySolicitation': 'My solicitation',
        'publicAgent': 'Public Agent',
        'wait': 'Wait',
        'normal': 'Normal',
        'blocked': 'Blocked',
        'termination': 'Termination',
        'newList':'New',
        'policyList':'Policy',
        'outcomeEvaluationType':'Outcome Evaluation Type',
        'outcomeEvaluationApply':'Outcome Evaluation Apply',
        'techArchievementSign':'techArchievementSign',
        'resultDisplay':'Result Display',
        'resultDisplay-Big':'R-D',
        'resultType':'Result Type',
        'transferScope':'Transfer Scope',
        'exhibitName':'Exhibit Name',
        'unitIntroduce':'Unit Introduce',
        'projectExhibitIntroduce':'Introduce of project and exhibit',
        'exhibitSpecificationSpecialDemand':'Exhibit specifications and special Show demand',
        'exhibitPhotoVideo':'Exhibit photo and video',
        'otherMattersExplain':'Other matters that need to be explained',
        'resultInnovation':'Result innovation',
        'teamCircumstance':'Team circumstance',
        'progressDisplay':'Progress Display',
        'progress':'Progress',
        'downloadContract': 'Download Contract',
        'pushExpert': 'Pushed Expert',
        'presentationContent': 'Presentation Content',
        'disciplineClassification': 'Discipline Classification',
        'resultRegistry': 'Result Registry',
        'unlimited': 'unlimited',
        'example': 'example',
        'provinceInnovation':'province innovation',
        'abutmentStatus': 'abutment status',
        'leadLevel':'advanced level',
        'addInnovationHall': 'add innovation hall',
        'myInputShow': 'my inputShow',
        'talentBelong': 'talent belong',

    },
    different: {
    },
}
